  /* Profile.css */
  .payment-check {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /* Profile.module.css */
.textbox {
    padding: 0.75rem 1rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    width: 100%;
  }
  
  .btn {
    background-color: #4a90e2;
    color: white;
    padding: 0.75rem 1rem;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
  }
  
  .btn:hover {
    background-color: #357ab8;
  }
  
  .glass {
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(10px);
    border-radius: 0.75rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .error {
    color: red;
    margin-top: 1rem;
  }
  
    