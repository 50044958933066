body{
    background-color: #808080;
}


.register .container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

  
  .glass {
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 10px;
    backdrop-filter: blur(10px);
    padding: 2rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .title {
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .text-5xl {
    font-size: 3rem;
    font-weight: bold;
  }
  
  .py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  
  .text-xl {
    font-size: 1.25rem;
    text-align: center;
    color: #808080;
  }
  
  .text-gray-500 {
    color: #808080;
  }
  
  .profile {
    justify-content: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  
  .profile_img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
  }
  
  .textbox {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    font-size: 1rem;
  }
  
  .name {
    justify-content: space-between;
  }
  
  .btn {
    padding: 0.75rem 1rem;
    border: none;
    border-radius: 0.375rem;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background-color: #6366f1;
    cursor: pointer;
  }
  
  .btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .text-center {
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  
  .text-red-500 {
    color: #ef4444;
  }
  

  .profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  
  .profile_img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
    margin-bottom: 1rem;
  }
  
  .btn {
    width: 100%;
    padding: 0.75rem 1rem;
    border: none;
    border-radius: 0.375rem;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background-color: #6366f1;
    cursor: pointer;
    margin-top: 1rem;
  }

  .textbox input {
    width: 100%;
    padding: 0.5rem 0.75rem;
    border: 1px solid #ccc;
    border-radius: 0.375rem;
    font-size: 1rem;
  }
  
  .textbox input:focus {
    outline: none;
    border-color: #6366f1;
    box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.2);
  }




  @media (max-width: 768px) {
    .glass {
      width: 90%;
    }
  
    .text-5xl {
      font-size: 2rem;
    }
  
    .text-xl {
      font-size: 1rem;
    }
  
    .profile_img {
      width: 80px;
      height: 80px;
    }
  
    .name {
      flex-direction: column;
      align-items: center;
    }
  }
  