/* Worksuccessful.module.css */

/* Base styles for larger screens */
.successContainer {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    height: 100vh;
    background-color: #f0f0f0; /* Background color for the entire screen */
    padding: 2rem;
    text-align: center;
  }
  
  .successImage {
    width: 200px;
    height: 200px;
    margin-bottom: 2rem;
  }
  
  .successMessage {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .successText {
    font-size: 3rem;
    color: #666;
    margin-bottom: 2rem;
  }
  
  .loginLink {
    display: inline-block;
    padding: 0.8rem 2rem;
    background-color: #3d7c4c;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .loginLink:hover {
    background-color: #2e5d3a;
  }
  
  /* Media query for smaller screens (e.g., mobile devices) */
  @media (max-width: 768px) {
    .successContainer {
      padding: 1rem;
    }
  
    .successImage {
      width: 150px;
      height: 150px;
    }
  
    .successMessage {
      font-size: 2rem;
    }
  
    .successText {
      font-size: 1rem;
    }
  
    .loginLink {
      padding: 0.5rem 1rem;
      font-size: 1rem;
    }
  }
  