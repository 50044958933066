.dashboard {
    background-color: #f1f1f1;
    padding: 20px;
    margin: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .dashboard h1 {
    color: black;
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
    flex-basis: 100%;
  }
  
  .dashboard p {
    color: #333;
    margin-bottom: 20px;
    text-align: center;
    flex-basis: 100%;
  }
  
  .dashboard button,
  .dashboard a {
    background-color: #3d7c4c;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 5px;
    flex-basis: 45%;
    text-align: center;
    text-decoration: none;
    font-size: 20px;
  }
  
  .dashboard button:hover,
  .dashboard a:hover {
    background-color: #45a049;
  }
  
  @media screen and (max-width: 768px) {
    .dashboard button,
    .dashboard a {
      flex-basis: 100%;
    }
  }
  /* .dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .image-container {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .image-container img {
    max-width: 100%;
    height: auto;
  }
   */