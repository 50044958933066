/* Styling for the certificate container */
.certificate-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  /* Styling for the certificate image */
  .certificate-image {
    max-width: 100%;
    height: 100vh;
  }
  
  /* Styling for the dynamic content (user name) */
  .dynamic-content {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    font-size: 2rem;
    text-align: center;
  }
  .user-name {
    font-size: 4rem;
  }
  
  /* Styling for the download button */
  .download-button {
    text-align: center;
    margin-top: 20px;
    background-color: white;
    font-size: 2rem;
    color: red
  }
  .download-button:hover{
    background-color: gray;
  }
  
  .receipt-modal-download-button {
    background-color: #3d7c4c;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .receipt-modal-download-button:disabled {
    background-color: gray;
    cursor: not-allowed;
  }
  
  .downloadText{
    color: red
  }
  /* Media Query for smaller screens */
  @media (max-width: 768px) {
    .dynamic-content {
      font-size: 1.5rem;
    }
  
    .user-name {
      font-size: 1.5rem;
    }
  }
  
 /* Media Query for smaller screens */


@media (min-width: 768px) {
    .dynamic-content {
      font-size: 2rem;
    }
    .user-name {
      font-size: 2rem; /* Adjust the font size for smaller screens */
    }
  }
  @media (min-width: 769px) {

    .user-name {
      font-size: 1.5rem; /* Adjust the font size for smaller screens */
    }
  }
  
  
  @media (min-width: 820px) {
    .user-name {
      font-size: 2rem; /* Adjust the font size for this screen size */
    }
  }
  
  @media (min-width: 912px) {
    .user-name {
      font-size: 2rem;
    }
  }
  
  @media (min-width: 1024px) {
    .user-name {
      font-size: 2rem;
    }
  }
  
  @media (min-width: 1280px) {
    .user-name {
      font-size: 2.5rem;
    }
  }